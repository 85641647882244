import React, {Component} from "react";
import Table from "./common/table";

class UploadConfirmationTable extends Component {
  render() {
    const {data, onSort, sortColumn, onRouteToValidate} = this.props;
    const columns = data.length > 0 ? Object.keys(data[0]) : [];

    return (
      <React.Fragment>
        <Table
          columns={columns}
          data={data}
          sortColumn={sortColumn}
          onSort={onSort}
          keyName="code"
        />
        <button className="btn btn-primary" onClick={onRouteToValidate}>
          Validate
        </button>
      </React.Fragment>
    )
      ;
  }
}

export default UploadConfirmationTable;
