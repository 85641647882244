import React, { Component, useState } from "react";
import { Col, Container, Row } from 'react-grid-system';
import { Link } from "react-router-dom";
import Joi from "joi";
import CsvReader from "./common/csvReader";
import UploadConfirmation from "./uploadConfirmation";
import UploadValidation from "./uploadValidation";
import UploadProcessing from "./uploadProcessing";
import { toast, ToastContainer } from "react-toastify";
import Loader from "./common/loader";
import { useNavigate } from "react-router-dom";
import API from "../services/api";


export const ProductUploader = (props) => {

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [step, setStep] = useState({
    id: 1,
    name: "Upload CSV",
    example_document: "productUploadExample.csv"
  });
  const [loading, setLoading] = useState(false);

  const schema = {
    "Handle": Joi.string().required(),
    "Variant Barcode": Joi.string().allow(""),
    "Variant SKU": Joi.string().required(),
    "Variant Price": Joi.number().required(),
    "Cost per item": Joi.number().required(),
    "StyleId": Joi.string().required(),
    "SKU": Joi.string().allow(""),
    "StyleSize": Joi.string().allow(""),
    "Style Color": Joi.string().allow(""),
    "Normalized Color": Joi.string().allow(""),
    "Distinct Color": Joi.string().allow(""),
    "Graphic": Joi.string().allow(""),
    "Title": Joi.string().allow(""),
    "Macro Brand": Joi.string().allow(""),
    "Main Vendor": Joi.string().allow(""),
    "Product Vendor": Joi.string().allow(""),
    "Vendor Collection": Joi.string().allow(""),
    "Vendor Category": Joi.string().allow(""),
    "Class": Joi.string().allow(""),
    "BA": Joi.string().allow(""),
    "Season": Joi.string().allow(""),
    "Delivery": Joi.string().allow(""),
    "Year": Joi.string().allow(""),
  };

  const handleSubmit = async (data) => {
    setData(data);

    const step = {
      id: 2,
      name: "Confirm Data"
    };

    handleUpdateStep(step);
  };

  const handleUpdateStep = async (step) => {
    if (step.id === 4) {
      await API.startImport(data, toast);
      navigate("/imports-list");
    }

    setStep(step);
  };

  const handleUpdateData = (data) => {
    setData(data);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="pageContent">
        <Row>
          <Col>
            <div className="btn btn-primary">
              Back
              <Link className="stretched-link" to="/imports-list" />
            </div>
          </Col>
        </Row>
        <div className="row pt-4">
          <span className="col-4"> <h5>Step:</h5> {step.name} <br /> Sample: <a href="https://docs.google.com/spreadsheets/d/11JD1CuwXea_p5CwGEMdVjcx0e__Vs0ixwPRL70IBhYk/edit?usp=sharing" target="_blank">productUploadExample.csv</a> </span>
          <span className="col-5">
            {loading && <Loader />}
          </span>
        </div>
        <div className="row pt-4 justify-content-center">
          {step.id === 1 && <CsvReader doSubmit={handleSubmit} />}
          {step.id === 2 &&
            <UploadConfirmation data={data} updateStep={handleUpdateStep} updateData={handleUpdateData} />}
          {step.id === 3 &&
            <UploadValidation data={data} updateStep={handleUpdateStep} updateData={handleUpdateData} schema={schema} />}
        </div>
      </div>
    </React.Fragment>
  )
    ;
}

export default ProductUploader;

