import API from "./api";
import _ from "lodash";

const ATTRIBUTES_NAMES = [
  "SKU",
  "Size",
  "StyleSize",
  "Style Color",
  "Normalized Color",
  "Distinct Color",
  "Graphic",
  "A Title",
  "Macro Brand",
  "Main Vendor",
  "Product Vendor",
  "Vendor Collection",
  "Vendor Category",
  "Class",
  "BA",
  "Season",
  "Delivery",
  "Year"
]

export function formatAttributeName(attributeName) {
  return attributeName.trim().replace(' ', '_').toLowerCase();
}

export function prepareAttributes(row) {
  const attributes = []
  // const attributes = [
  //   {
  //     attribute: row["Option1 Name"],
  //     name: row["Option1 Value"],
  //   },
  //   {
  //     attribute: row["Option2 Name"],
  //     name: row["Option2 Value"],
  //   },
  // ];
  for (const attributeName of ATTRIBUTES_NAMES) {
    if (typeof row[attributeName] !== 'undefined' && row[attributeName].trim()) {
      attributes.push({
        attribute: formatAttributeName(attributeName),
        name: row[attributeName],
      })
    }
  }

  return attributes
}

export async function deleteProductLinkedOptions(attributeId, productId, toast) {
  const productLinkedOptions = await API.getProductLinkedOptions(
    attributeId,
    productId,
    toast
  );
  if (productLinkedOptions.data.errorMesssage)
    return toast(productLinkedOptions.data.errorMessage, {autoClose: false});
  if (productLinkedOptions.data.message.length > 0) {
    for (const linkedOptionData of productLinkedOptions.data.message) {
      await API.deleteProductLinkedOption(linkedOptionData.id)
    }
  }
}

export async function createAttributes(attributes, productId, toast) {
  for (const attribute of attributes) {
    const productAttributeResponse = await API.getProductAttribute(
      attribute,
      toast
    );
    if (productAttributeResponse.data.errorMesssage)
      return toast(productAttributeResponse.data.errorMessage, {autoClose: false});
    if (productAttributeResponse.data.message.length > 0) {
      attribute.id = productAttributeResponse.data.message[0].id;
    } else {
      attribute.id = await API.createProductAttribute(attribute.attribute, toast).then(response => {
        return response.data.message[0].id
      });
    }
    const productAttributeOptionResponse =
      await API.getProductAttributeOption(attribute.id, attribute.name, toast);
    if (productAttributeOptionResponse.data.errorMesssage)
      return toast(productAttributeOptionResponse.data.errorMessage, {autoClose: false});
    if (productAttributeOptionResponse.data.message.length > 0) {
      for (const productAttributeOption of productAttributeOptionResponse.data.message) {
        if (productAttributeOption.rec_name === attribute.name) {
          attribute.option = productAttributeOption;
        }
      }
    }

    if (!attribute.hasOwnProperty('option')) {
      attribute.option = await API.createAttributeOption(
        attribute.id,
        attribute.name,
        toast
      ).then(response => response.data.message[0]);
    }

    const payload = {
      attribute: attribute.id,
      valueSelection: attribute.option.id,
      product: productId,
    };
    if (!_.isEmpty(attribute.option))
      await deleteProductLinkedOptions(attribute.id, productId)
    await API.addAttributesToProduct(payload, toast);
  }
};

export async function startShopifyProductsUpdate(shopifyProductsData, toast) {
  const result = await API.startShopifyProductsUpdate(
    shopifyProductsData,
    toast
  );
  if (result.data.errorMesssage)
    return toast(result.data.errorMessage, {autoClose: false});
}

export async function getShopifyOperationStatus(bulkOperationId, toast) {
  const result = await API.getShopifyOperationStatus(bulkOperationId, toast);
  if (result?.data?.errorMesssage)
    return toast(result.data.errorMessage, {autoClose: false});

  return result.data.message
}

export async function getShopifyProductsCreateResults(bulkOperationId, shopifyProductsData, toast) {
  const result = await API.getShopifyProductsCreateResults(bulkOperationId, shopifyProductsData, toast);
  if (result.data.errorMesssage)
    return toast(result.data.errorMessage, {autoClose: false});

  return result.data.message
}

export async function startShopifyProductsCreate(shopifyProductsData, toast) {
  const result = await API.startShopifyProductsCreate(
    shopifyProductsData,
    toast
  );
  if (result.data.errorMesssage)
    return toast(result.data.errorMessage, {autoClose: false});

  return result.data.message
}

export async function createListings(shopifyProductsData, fulfilProductsData, toast) {
  const result = await API.createListings(
    shopifyProductsData,
    fulfilProductsData,
    toast
  );

  if (result?.data.errorMesssage) {
    return toast(result.data.errorMessage, {autoClose: false});
  }

  return result.data.message
}
