import React, { useState } from "react";
import DT from "react-data-table-component";
import Pagination from "./pagination";
import Loader from "../common/loader";


const customStyles = {
  headRow: {
    style: {
      minHeight: '50px',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      fontSize: "15px",
    }
  },
  rows: {
    style: {
      minHeight: '60px',
    }
  },
  headCells: {
    style: {
      padding: '12px',
      fontSize: '15px',
      fontWeight: '700',
      lineHeight: '20px',
    },
  },
  cells: {
    style: {
      padding: '12px',
      fontSize: '15px'
    },
  },
};

const DataTable = (props) => {
  const [rowsPerPage, setRowsPerPage] = useState(
    props.rowsPerPage ? props.rowsPerPage : null
  );
  const [pagesHistory, setPagesHistory] = useState(["false"]);

  function onChangePage(isNext) {
    let history = pagesHistory;
    if (isNext) {
      history.push(props.nextFromKey);
    } else {
      history.pop();
    }
    setPagesHistory(history);

    let fromKey = isNext ? props.nextFromKey : getPrevFormKey();
    props.onChangePage(fromKey);
  }

  function onChangeRowsPerPage(rowsPerPage) {
    setPagesHistory(["false"]);
    props.onChangeRowsPerPage(rowsPerPage);
  }

  function getPrevFormKey() {
    return pagesHistory[pagesHistory.length - 1] !== undefined
      ? pagesHistory[pagesHistory.length - 1]
      : false;
  }

  function prevActive() {
    return pagesHistory[pagesHistory.length - 1] !== undefined
      ? pagesHistory[pagesHistory.length - 1] !== "false"
      : false;
  }

  return (
    <div>
      <h1>
        <div className="row pt-4 justify-content-center">
          {props.title}
        </div>
      </h1>
      <DT
        customStyles={customStyles}
        progressComponent={<Loader />}
        {...props}
      />
      {rowsPerPage && (
        <Pagination
          rowsPerPage={rowsPerPage}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          prevActive={prevActive()}
          nextActive={!!props.nextFromKey}
        />
      )}
    </div>
  );
};

export default DataTable;
