import {Auth} from "aws-amplify";


/**
 * This represents some generic auth provider API, like Firebase.
 */
export default class AWSCognitoAuth {

  signOut() {
    Auth.signOut()
      .then(data => {
        console.log("SIGNOUT DATA: ", data);
        window.location.replace("/");
      })
      .catch(err => console.log(err));
  }

  signIn({username, password}) {
    Auth.signIn({username: username, password: password})
      .then(data => {
        console.log("successfully signed in: ", Auth);
        console.log("Sign in data: ", data);
        window.location.replace("/");
      })
      .catch(err => console.log(err));
  }

  checkUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (!user) console.log("USER NOT LOGGED IN :", user);
      window.location.replace("/");
      return user;
    } catch (ex) {
      console.log("An exception has occurred while checking the users authentication: ", ex);
    }

  };

}
