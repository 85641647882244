import "@aws-amplify/ui-react/styles.css";
import React from "react";
import AWSCognitoAuth from "../../services/auth";


const Signout = () => {
  AWSCognitoAuth.prototype.signOut();


  return (
    <div className="row justify-text-center">
      <h1>Logging out..</h1>
    </div>
  );
};

export default Signout;