import React, {Component} from "react";
import _ from "lodash";
import CsvReader from "./csvReader";

class TableBody extends Component {
  renderCell = (item, column) => {
    return _.get(item, column);
  };

  createKey = (item, column) => {
    return _.get(item, column) + column;
  };

  render() {
    const {data, columns} = this.props;

    return (
      <tbody>
      {data.map((item, index) => (
        <tr key={index}>
          {columns.map(column => (
            <td key={this.createKey(item, column)}>
              {column === "isValid" ? "IS VALID" : this.renderCell(item, column)}
            </td>
          ))}
        </tr>
      ))}
      </tbody>
    );
  }
}

export default TableBody;
