import React, { useState } from "react";
import styles from "./dataTable.module.scss";

const options = [
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "15", value: 15 },
];

const Pagination = (props) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);

  function onChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value));

    props.onChangeRowsPerPage(event.target.value);
  }

  let prevBtnClass = [styles.btnPagination];
  let nextBtnClass = [styles.btnPagination];

  if (!props.prevActive) {
    prevBtnClass = [...prevBtnClass, styles.btnDisabled];
  }
  if (!props.nextActive) {
    nextBtnClass = [...nextBtnClass, styles.btnDisabled];
  }

  return (
    <div className={styles.tablePagination}>
      <div className={styles.itemsPerPage}>
        <span>Rows per page </span>
        <select
          name="rowsPerPage"
          onChange={onChangeRowsPerPage}
          defaultValue={rowsPerPage}
        >
          {options.map((option, key) => {
            return (
              <option key={key} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
      </div>

      <div className={styles.paginationButtons}>
        <button
          className={prevBtnClass.join(" ")}
          disabled={!props.prevActive}
          onClick={() => props.onChangePage(false)}
        >
          <svg
            width="7"
            height="13"
            viewBox="0 0 7 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.75879 12.7462C6.43719 13.0846 5.91576 13.0846 5.59415 12.7462L0.241206 7.11283C-0.0804026 6.77437 -0.0804025 6.22563 0.241206 5.88717L5.59415 0.253842C5.91576 -0.0846132 6.43719 -0.0846132 6.75879 0.253842C7.0804 0.592296 7.0804 1.14104 6.75879 1.47949L1.98818 6.5L6.75879 11.5205C7.0804 11.859 7.0804 12.4077 6.75879 12.7462Z"
              fill="black"
            />
          </svg>
        </button>
        <button
          className={nextBtnClass.join(" ")}
          disabled={!props.nextActive}
          onClick={() => props.onChangePage(true)}
        >
          <svg
            width="7"
            height="13"
            viewBox="0 0 7 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.241206 0.253841C0.562814 -0.0846136 1.08424 -0.0846136 1.40585 0.253841L6.75879 5.88717C7.0804 6.22563 7.0804 6.77437 6.75879 7.11283L1.40585 12.7462C1.08424 13.0846 0.562814 13.0846 0.241206 12.7462C-0.0804021 12.4077 -0.0804021 11.859 0.241206 11.5205L5.01182 6.5L0.241206 1.47949C-0.0804021 1.14104 -0.0804021 0.592295 0.241206 0.253841Z"
              fill="black"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
