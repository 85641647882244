import React, {useState, useEffect} from "react";
import * as Papa from "papaparse"

const CsvReader = ({doSubmit}) => {
  const [csvFile, setCsvFile] = useState();
  const [csvArray, setCsvArray] = useState([]);

  const processCSV = (str, delim = ",") => {
    const csvValues = Papa.parse(str.trim(), {
      header: true,
    })

    setCsvArray(csvValues.data);
    doSubmit(csvValues.data);
  };

  const submit = () => {
    const file = csvFile;
    const reader = new FileReader();

    reader.onload = function (e) {
      const text = e.target.result;
      processCSV(text);
    };

    reader.readAsText(file);
  };

  useEffect(() => {
    if (csvFile) {
      submit();
    }
  }, [csvFile]);

  const trimField = (field) => {
    return field.replace(/^\"/, "").replace(/\"$/, "")
  }

  return (
    <div className="row justify-content-center">
      <div className="col-10">
        <form id="csv-form">
          <div className="input-group mb-3">
            <input
              type="file"
              accept=".csv"
              id="csvFile"
              className="form-control"
              onChange={(e) => {
                setCsvFile(e.target.files[0]);
              }}
            >
            </input>
            <button
              type="button"
              className="btn btn-primary input-group-text"
              onClick={(e) => {
                e.preventDefault();
                if (csvFile) submit();
              }}>
              Upload
            </button>
          </div>
        </form>
      </div>
    </div>
  )
    ;
};

export default CsvReader;
