import React, { useState, useEffect } from "react";
import {Col, Container, Row} from 'react-grid-system';
import DataTable from "./common/dataTable";
import {Link, useNavigate} from "react-router-dom";
import API from "../services/api";
import { toast, ToastContainer } from "react-toastify";
import { STATUSES, STATUSES_BG } from "../helpers/constants";
import { Auth } from "aws-amplify";

const ImportsList = (props) => {
  const [loading, setLoading] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(false)
  const [currentFromKey, setCurrentFromKey] = useState(false)
  const [imports, setImports] = useState([])
  const [nextFromKey, setNextFromKey] = useState(false)

  const [columns, setColumns] = useState([
        {
          name: 'Created at',
          cell: row => (new Date(row.createdAt).toLocaleString()),
        },
        {
          name: 'FF Status',
          cell: row => <span onClick={(event) => ffClicked(row)} className={"badge " + STATUSES_BG[row.ffStatus] + " span-button"}>{STATUSES[row.ffStatus]}</span>,
        },
        {
          name: 'Shopify Status',
          cell: row => <span onClick={(event) => shopifyClicked(row)} className={"badge " + STATUSES_BG[row.shopifyStatus] + " span-button"}>{STATUSES[row.shopifyStatus]}</span>,
        },
        {
          name: 'Listings Status',
          cell: row => <span onClick={(event) => listingsClicked(row)} className={"badge " + STATUSES_BG[row.listingsStatus] + " span-button"}>{STATUSES[row.listingsStatus]}</span>,
        },
        {
          name: 'Products count',
          cell: row => row.productsCount,
        },
        // {
        //   name: 'Succeeded products count',
        //   cell: row => row.succeededProductsCount,
        // },
        // {
        //   name: 'Failed products count',
        //   cell: row => row.failedProductsCount,
        // },

  ]);

  const navigate = useNavigate();

  let updateInterval = null;

  const rowClicked = (row) => {
    // navigate(`/products-list/${row.id}`);
    // console.log(row);
  }
  
  const ffClicked = (row) => {
    navigate(`/logs/${row.id}__ff`);
    console.log(row);
  }

  const shopifyClicked = (row) => {
    navigate(`/logs/${row.id}__shopify`);
    console.log(row);
  }

  const listingsClicked = (row) => {
    navigate(`/logs/${row.id}__listing`);
    console.log(row);
  }

  const loadData = (silent = false) => {
    if (!silent) {
      setLoading(true)
    }

    API.getImports(rowsPerPage, currentFromKey, toast).then(response => {
      if (response.data?.errors) {
        console.log(response.data.errors);
      } else {
        setImports(response.data.data.Items)
      }
    }).finally(() => setLoading(false))
  }

  const restartImport = (importSK, toast) => {
    API.restartImport(importSK, toast).then(response => {
      if (response.data?.errors) {
        console.log(response.data.errors);
      } else {
        loadData()
      }
    })
  }

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(user => {
      if (user.attributes?.email === 'nazar@lindsayhatcher.com') {
        setColumns([
         ...columns,
          {
            cell: row => <div className="btn btn-primary" onClick={(event) => restartImport(row.SK)}>Restart</div>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          }
        ]) 
      }
    });

    loadData()
    if (!updateInterval) {
      updateInterval = setInterval(() => {
        loadData(true)
      }, 5000)
    }

    return () => {
      clearInterval(updateInterval)
    }
  }, [])

  return (
  <div className="pageContent">
      <ToastContainer />
      <Container>
        <Row>
          <Col>
            <div className="btn btn-primary">
              Back
              <Link className="stretched-link" to="/" />
            </div>
          </Col>
          <Col offset={{lg: 10}} lg={11}>
              <div className="btn btn-primary">
                Create import
                <Link className="stretched-link" to="/product-uploader" />
              </div>
          </Col>
          <Col offset={{lg: 1}} lg={10}>
              <DataTable
                title="Imports"
                progressPending={loading}
                onChangePage={setCurrentFromKey}
                keyField="SK"
                paginationServer={true}
                onChangeRowsPerPage={setRowsPerPage}
                rowsPerPage={rowsPerPage}
                nextFromKey={nextFromKey}
                striped={true}
                noHeader={true}
                onRowClicked={rowClicked} 
                columns={columns}
                data={imports}
              />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ImportsList
