import React, { Component } from "react";
import UploadConfirmationTable from "./uploadConfirmationTable";

class UploadConfirmation extends Component {
  state = {
    data: [],
    previewData: [],
    columns: [],
    currentPage: 1,
    pageSize: 4,
    searchQuery: "",
    selectedGenre: null,
    sortColumn: { path: "title", order: "asc" },
    previewAmount: 5,
  };

  async componentDidMount() {
    await this.initData();
    await this.getPreviewData();
  }

  getPreviewData = async () => {
    const { data, previewAmount } = this.state;
    this.setState({previewData: data.slice(0, previewAmount + 1)})
  };

  initData = async (callback) => {
    this.setState({ data: this.props.data }, callback);
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleRouteToValidate = async () => {
    const items = [...this.state.data];
    const step = {
      id: 3,
      name: "Data Validation",
    };
    this.props.updateData(items);
    this.props.updateStep(step);
  };

  render() {
    const { length: count } = this.state.data.length;
    const { sortColumn, previewAmount, previewData } = this.state;

    if (count === 0) return <p>There are no rows in the csv.</p>;

    return (
      <div className="row">
        <div className="col-10">
          <p>
            Showing a preview of first {previewAmount} rows from the uploaded
            csv.
          </p>
          <UploadConfirmationTable
            data={previewData}
            sortColumn={sortColumn}
            onSort={this.handleSort}
            onRouteToValidate={this.handleRouteToValidate}
          />
        </div>
      </div>
    );
  }
}

export default UploadConfirmation;
