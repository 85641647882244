import React, { useEffect, useState } from "react";
import { Col, Container, Row } from 'react-grid-system';
import DataTable from "./common/dataTable";
import { Link, useParams } from "react-router-dom";
import API from "../services/api";
import { toast, ToastContainer } from "react-toastify";
import { input } from "aws-amplify";

const LogsList = (props) => {
  const [rowsPerPage, setRowsPerPage] = useState(false)
  const [currentFromKey, setCurrentFromKey] = useState(false)
  const [logs, setLogs] = useState([])
  const [loading, setLoading] = useState(false)
  const [severity, setSeverity] = useState(false)
  const [nextFromKey, setNextFromKey] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")

  const { logId } = useParams();
  const [logType, setLogType] = useState(logId.split("__")[1] ?? "");

  let inputDelayTimestamp = null;

  useEffect(() => {
    loadData()
  }, [])

  const loadData = () => {
    setLoading(true)
    let logIdParam = logId
    if (severity) {
      logIdParam = logId + "__" + severity
    }

    API.getLogs(logIdParam, searchTerm, rowsPerPage, currentFromKey, toast).then(response => {
      if (response.data?.errors) {
        console.log(response.data.errors);
      } else {
        setLogs(response.data.data.Items)
      }
    }).finally(() => setLoading(false))
  }

  useEffect(() => {
    loadData()
  }, [severity])

  useEffect(() => {
    if (inputDelayTimestamp) {
      clearTimeout(inputDelayTimestamp)
    }

    inputDelayTimestamp = setTimeout(() => {
      loadData()
    }, 500)
  }, [searchTerm])

  const filterComponent = () => {
    return (
      <Row>
        <Col>
          <input onChange={(e) => setSearchTerm(e.target.value)} />
        </Col>
        <Col>
          <button onClick={() => setSeverity(false)} className="btn btn-light">All</button>
        </Col>
        <Col>
          <button onClick={() => setSeverity('info')} className="btn btn-info">Info</button>
        </Col>
        <Col>
          <button onClick={() => setSeverity('warn')} className="btn btn-warning">Warning</button>
        </Col>
        <Col>
          <button onClick={() => setSeverity('error')} className="btn btn-danger">Error</button>
        </Col>
      </Row>
    )
  }

  return (
    <div className="pageContent">
      <ToastContainer />
      <Container>
        <Row>
          <Col>
            <div className="btn btn-primary">
              Back
              <Link className="stretched-link" to="/imports-list" />
            </div>
          </Col>
        </Row>
        <Row>
          <Col offset={{ lg: 1 }} lg={10}>
            <DataTable
              title={logType ? logType.toUpperCase() + " LOGS" : "LOGS"}
              progressPending={loading}
              onChangePage={setCurrentFromKey}
              keyField="SK"
              paginationServer={true}
              onChangeRowsPerPage={setRowsPerPage}
              rowsPerPage={rowsPerPage}
              nextFromKey={nextFromKey}
              striped={true}
              subHeader={true}
              subHeaderComponent={filterComponent()}
              noHeader={true}
              columns={[
                {
                  name: 'Created at',
                  cell: row => (new Date(row.createdAt).toLocaleString()),
                },
                {
                  name: 'Message',
                  cell: row => row.message,
                },
              ]}
              data={logs}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LogsList
