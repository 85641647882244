import React, { Component } from "react";
import CsvReader from "./common/csvReader";
import UploadConfirmation from "./uploadConfirmation";
import UploadValidation from "./uploadValidation";
import UpdateProcessing from "./updateProcessing";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import Joi from "joi";
import Loader from "./common/loader";
import API from "../services/api";

class productUpdater extends Component {
  constructor(props) {
    super(props)
    this.setLoading = this.setLoading.bind(this);
  }

  state = {
    step: {
      id: 1,
      name: "Upload CSV",
    },
    data: [],
    loading: false,
  };

  schema = {
    "Variant Barcode": Joi.string().allow(""),
    "Variant SKU": Joi.string().required(),
    "Variant Price": Joi.number().allow(""),
    "Cost per item": Joi.number().allow(""),
    "StyleId": Joi.string().allow(""),
    "SKU": Joi.string().allow(""),
    "StyleSize": Joi.string().allow(""),
    "Style Color": Joi.string().allow(""),
    "Normalized Color": Joi.string().allow(""),
    "Distinct Color": Joi.string().allow(""),
    "Graphic": Joi.string().allow(""),
    "Title": Joi.string().allow(""),
    "Macro Brand": Joi.string().allow(""),
    "Main Vendor": Joi.string().allow(""),
    "Product Vendor": Joi.string().allow(""),
    "Vendor Collection": Joi.string().allow(""),
    "Vendor Category": Joi.string().allow(""),
    "Class": Joi.string().allow(""),
    "BA": Joi.string().allow(""),
    "Season": Joi.string().allow(""),
    "Delivery": Joi.string().allow(""),
    "Year": Joi.string().allow(""),
  };

  setLoading(loading) {
    this.setState({loading: loading})
  }

  render() {
    console.log("Version 1.01");
    const handleSubmit = (data) => {
      this.setState({ data });
      const step = {
        id: 2,
        name: "Confirm Data",
      };
      handleUpdateStep(step);
    };

    const handleUpdateStep = (step) => {
      this.setState({ step });
    };
    const handleUpdateData = (data) => {
      this.setState({ data });
    };

    const { step, data } = this.state;
    return (
      <React.Fragment>
        <ToastContainer />
        <div className="container">
          <div className="row pt-4">
            <span className="col-4">
              {" "}
              <h5>Step:</h5> {step.name}
            </span>
            <span className="col-5">
              {this.state.loading && <Loader />}
            </span>
          </div>
          <div className="row pt-4 justify-content-center">
            {step.id === 1 && <CsvReader doSubmit={handleSubmit} />}
            {step.id === 2 && (
              <UploadConfirmation
                data={data}
                updateStep={handleUpdateStep}
                updateData={handleUpdateData}
              />
            )}
            {step.id === 3 && (
              <UploadValidation
                data={data}
                updateStep={handleUpdateStep}
                updateData={handleUpdateData}
                schema={this.schema}
              />
            )}
            {step.id === 4 && (
              <UpdateProcessing
                data={data}
                updateStep={handleUpdateStep}
                updateData={handleUpdateData}
                setLoading={this.setLoading}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default productUpdater;
