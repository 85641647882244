import React, { Component, useEffect } from "react";
import { toast } from "react-toastify";
import API from "../services/api";
import Loader from "./common/loader";
import { startShopifyProductsCreate, createListings, getShopifyOperationStatus, getShopifyProductsCreateResults } from "../services/utils";
import _ from "lodash";
import { prepareAttributes } from "../services/utils";
import { createAttributes } from "../services/utils";

class UploadProcessing extends Component {
  state = {
    data: [],
    errors: [],
    createdItems: [],
    columns: [],
    currentPage: 1,
    pageSize: 4,
    searchQuery: "",
    selectedGenre: null,
    sortColumn: { path: "title", order: "asc" },
    fulfilURL: "https://bbcicecream.fulfil.io/client/#/model/product.product/",
    alreadyExists: [],
    productIdsFf: [],
    shopifyProductsResult: [],
    fulfilItemsCreated: false,
  };

  async componentDidMount() {
    await this.initData();
    this.createShopifyProducts(this.props.data)
    await this.processItems();
    toast("Everything has loaded!");
    console.log("Setting state")
    this.setState({ fulfilItemsCreated: true });
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("Component did update")
    console.log(prevState.shopifyProductsResult)
    console.log(this.state.shopifyProductsResult)
    console.log(this.state.fulfilItemsCreated)
    if (this.state.shopifyProductsResult.length > 0 && this.state.fulfilItemsCreated) {
      toast("Start listings creation")
      createListings(this.state.shopifyProductsResult, this.state.productIdsFf, toast).then(response => {
        toast("Listings creation finished")
      });
    }
  }

  convertGramsToPounds = async (weightInGrams) => {
    const gramsPerPound = 0.00220462;
    return weightInGrams * gramsPerPound;
  };

  createShopifyProducts = (data) => {
    toast("Starting Shopify products creation")
    startShopifyProductsCreate(data, toast).then(response => {
      if (response) {
        const startShopifyBulkOperationId = response.bulkOperationId
        let productsCreateInterval = setInterval(() => {
          getShopifyOperationStatus(startShopifyBulkOperationId, toast).then(response => {
            debugger
            if (response?.status === "COMPLETED") {
              clearInterval(productsCreateInterval)
              getShopifyProductsCreateResults(startShopifyBulkOperationId, data, toast).then(response => {
                toast("Shopify products created")
                let products = [];
                for (const message of response) {
                  if (message.hasOwnProperty("userErrors") && message.userErrors.length > 0) {
                    toast(message.userErrors[0].message, { autoClose: false });
                  } else {
                    products.push(message.product)
                  }
                }

                this.setState({ shopifyProductsResult: products })
              })
            }

          })
        }, 3000)

      }
    })
  }

  createItem = async (item) => {
    const { createdItems, productIdsFf } = this.state;
    const customProductType = item["Custom Product Type"];
    let category = await API.getProductCategory(customProductType, toast);
    if (category === undefined) return;
    if (category.data.errorMessage) {
      toast(category.data.errorMessage, { autoClose: false });
      console.log(
        "An error has occurred while getting the category: ",
        category.data.errorMessage
      );
      return;
    }
    if (category.data.message.length === 0) {
      category = await API.createProductCategory(customProductType, toast);
    }
    const categoryId = category.data.message[0].id;
    let productTemplate = await API.getProductTemplate(item["Title"], toast);
    if (productTemplate === undefined) return;
    if (productTemplate.data.errorMessage) {
      toast(productTemplate.data.errorMessage, { autoClose: false });
      console.log(
        "An error has occurred while getting the category: ",
        productTemplate.data.errorMessage
      );
      return;
    }
    if (productTemplate.data.message.length === 0) {
      productTemplate = await API.createProductTemplate(
        categoryId,
        item["Title"],
        item["StyleId"],
        toast
      );
    }
    const productTemplateId = productTemplate.data.message[0].id;

    const weightInPounds = await this.convertGramsToPounds(
      item["Variant Grams"]
    );
    const salable = true;
    let product = await API.getProduct(item["Variant SKU"], toast);
    if (product === undefined) return;
    if (product.data.errorMessage) {
      toast(product.data.errorMessage, { autoClose: false });
      console.log(
        "An error has occurred while getting the product: ",
        productTemplate.data.errorMessage
      );
      return;
    }
    if (product.data.message.length > 0) {
      const { alreadyExists, productIdsFf } = this.state;
      alreadyExists.push(product.data.message[0]["rec_name"]);
      productIdsFf.push({ id: product.data.message[0]["id"], handle: item["Handle"], sku: item["Variant SKU"] });
      this.setState({ alreadyExists, productIdsFf });
      return;
    }
    product = await API.createProduct(
      item["Variant SKU"],
      salable,
      productTemplateId,
      item["Variant Price"],
      item["Cost per item"],
      item["Title"],
      weightInPounds,
      toast
    );
    if (product === undefined) return;
    if (product.status === 200 && product.data.message.length > 0) {
      createdItems.push(product.data.message[0]);
      productIdsFf.push({ id: product.data.message[0]["id"], handle: item["Handle"], sku: item["Variant SKU"] });
      this.setState({ createdItems, productIdsFf });
    } else {
      console.log(
        "An error occurred while creating the product: ",
        product.data
      );
      toast(product.data, { autoClose: false });
    }

    if (product.data.message.length) {
      // Check if Product Supplier Exists
      const { id: productId } = product.data.message[0];
      let productSupplier = await API.getProductSupplier(productId, toast);
      if (productSupplier === undefined) return;
      if (productSupplier.data.errorMessage) {
        toast(productSupplier.data.errorMessage, { autoClose: false });
        console.log(
          "An error has occurred while getting the product supplier: ",
          productSupplier.data.errorMessage
        );
        return;
      }
      if (productSupplier.data.message.length === 0) {
        // Check if Party Contact Exists
        let partyContact = await API.getPartyContact(item["Vendor"], toast);
        if (partyContact === undefined) return;
        if (partyContact.data.errorMessage) {
          toast(partyContact.data.errorMessage, { autoClose: false });
          console.log(
            "An error has occurred while getting the product supplier: ",
            productSupplier.data.errorMessage
          );
          return;
        }
        if (partyContact.data.message.length === 0) {
          partyContact = await API.createPartyContact(item["Vendor"], toast);
        }
        const { id: partyContactId } = partyContact.data.message[0];
        await API.createProductSupplier(productId, partyContactId, toast);
        const attributes = prepareAttributes(item)
        await createAttributes(attributes, productId, toast);
        let barcodeType = 'other'
        if (item["Variant Barcode"]) {
          const valid = await API.validateBarcode(item["Variant Barcode"], toast);
          if ((valid && (!item.hasOwnProperty("Variant Barcode Type")) || item["Variant Barcode Type"] === 'GTIN')) {
            barcodeType = "gtin"
          }
        }

        await API.createBarcode(
          productId,
          item["Variant Barcode"],
          barcodeType,
          toast
        );
      }
    }
  };



  timeout = (ms) => {
    return new Promise((res) => setTimeout(res, ms));
  };

  processItems = async () => {
    this.props.setLoading(true)
    const { data } = this.state;
    let count = 0;
    for (const item of data) {
      count++;
      if (count >= 3) {
        count = 0;
        // console.log("Timeout: 5000ms");
        // await this.timeout(5000);
        await this.createItem(item);
      } else {
        await this.createItem(item);
      }
    }
    this.props.setLoading(false)
  };

  initData = async () => {
    this.setState({ data: this.props.data });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedGenre,
      searchQuery,
      data,
    } = this.state;

    return { totalCount: data.length, data };
  };

  handleSubmit = () => {
    const step = {
      id: 1,
      name: "Upload CSV",
    };
    const data = [];
    this.props.updateData(data);
    this.props.updateStep(step);
  };

  render() {
    const { data, createdItems, alreadyExists, fulfilURL } = this.state;

    return (
      <React.Fragment>
        <div className="row justify-content-center pb-3">
          <span>
            Created {createdItems.length} out of {data.length}{" "}
          </span>
          {createdItems.length > 0 && (
            <div>
              <ul>
                {createdItems.map((item, index) => {
                  return (
                    <li key={index}>
                      <a href={fulfilURL + item["id"]} target="_blank">
                        {item["rec_name"]}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <hr />
        <div className="row justify-content-center">
          {alreadyExists.length > 0 && (
            <div>
              <span>{alreadyExists.length} already exist in Fulfil.</span>
              <ul>
                {alreadyExists.map((product, index) => {
                  return <li key={index}>{product}</li>;
                })}
              </ul>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default UploadProcessing;
