import React from "react";

export default function Loader() {
  return (
    <div id="overlay" className="show">
      <div className="spinner-container">
        <div className="loading-spinner">
        </div>
        <div className="spinner-text">Processing. Please do not refresh the page.</div>
      </div>
    </div>
  );
}
