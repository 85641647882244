export const STATUSES = {
  created: "CREATED",
  processing: "PROCESSING",
  pending: "PENDING",
  completed: "COMPLETED",
  failed: "FAILED",
};

export const STATUSES_BG = {
  created: "bg-primary",
  processing: "bg-info",
  pending: "bg-warning",
  completed: "bg-success",
  failed: "bg-danger",
};
