import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";
import { ToastContainer } from "react-toastify";
import { Route, Routes } from "react-router-dom";
import NavBar from "./components/navBar";
import ProductUploader from "./components/productUploader";
import ProductUpdater from "./components/productUpdater";
import ImportsList from "./components/importsList";
import Signout from "./components/common/signout";
import NotFound from "./components/notFound";
import AppDashboard from "./components/appDashboard";
import ProductsList from "./components/productsList";
import LogsList from "./components/logsList";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Auth, Hub } from "aws-amplify";

import "@aws-amplify/ui-react/styles.css";

Amplify.configure(awsconfig);

function App() {
  useEffect(() => {
    let updateUser = async (authState) => {
      try {
        const currentSession = await Auth.currentSession();
        const { jwtToken } = await currentSession.idToken;
        window.localStorage.setItem("token", jwtToken);
      } catch {
        window.localStorage.setItem("token", null);
      }
    };
    Hub.listen("auth", updateUser); // listen for login/signup events
    updateUser(); // check manually the first time because we won't get a Hub event
    return () => Hub.remove("auth", updateUser); // cleanup
  }, []);

  return (
    <React.Fragment>
      <main className="container">
        <NavBar />
        <Routes>
          <Route exact path="/" element={<AppDashboard />} />
          <Route path="/signout" element={<Signout />} />
          <Route path="/product-uploader" element={<ProductUploader />} />
          <Route path="/imports-list" element={<ImportsList />} />
          <Route path="/products-list/:importId" element={<ProductsList />} />
          <Route path="/logs/:logId" element={<LogsList />} />
          <Route path="/product-updater" element={<ProductUpdater />} />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
    </React.Fragment>
  );
}

export default withAuthenticator(App, {
  hideSignUp: true,
});
