import React, { Component } from "react";
import { Link } from "react-router-dom";

class appDashboard extends Component {
  render() {
    return (
      <div className="container">
        <div className="row pt-4">
          <div className="col-4"></div>
          <div className="col-4">
            <h3>App Dashboard</h3>
          </div>
          <div className="col-4"></div>
        </div>
        <div className="row pt-4">
          <div className="col-4">
            <div
              className="card bg-light mb-3"
              style={{
                maxWidth: 18 + "rem",
              }}
            >
              <div className="card-body">
                <h5 className="card-title">Create Products</h5>
                <p className="card-text">
                  Upload a CSV File with one or more products to create in
                  Fulfil.
                </p>
                <Link className="stretched-link" to="/imports-list" />
              </div>
            </div>
          </div>
          <div className="col-4">
            <div
              className="card bg-light mb-3"
              style={{
                maxWidth: 18 + "rem",
              }}
            >
              <div className="card-body">
                <h5 className="card-title">Update Products</h5>
                <p className="card-text">
                  Upload a CSV File with one or more products to update in
                  Fulfil.
                </p>
                <Link className="stretched-link" to="/product-updater" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default appDashboard;
